@tailwind base;
@tailwind components;
@tailwind utilities;

input:invalid, textarea:invalid, select:invalid {
    box-shadow: none;
}

.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .3s ease-in-out;
}

.link-underline-black {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#0161AA, #0161AA)
}

.link-underline:hover {
    background-size: 100% 2px;
    background-position: 0 100%;
}

@layer base {

    [x-cloak] {
        display: none;
    }

    .menu-height {
        height: calc(100vh - 96px);
    }

    .search-height {
        max-height: calc(100vh - 96px);
    }

    .no-scrollbar::-webkit-scrollbar {
        @apply hidden;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    @font-face {
        font-family: "TradeGothicLTS";
        src: url("/fonts/TradeGothicLTS/Trade-Gothic-LT-Extended.woff") format("woff");
    }

    @font-face {
        font-family: "TradeGothicLTSBoldExt";
        src: url("/fonts/TradeGothicLTSBoldExt/TradeGothicLTStd-BoldExt.woff2") format("woff2");
    }

    @font-face {
        font-family: "UtopiaStd";
        src: url("/fonts/UtopiaStd/UtopiaStd-Disp.woff2") format("woff2");
    }
}

.signature__concent {
    max-height: 250px;
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.signature__concent ul {
    list-style: outside;
}

.signature__concent ul.list-auto {
    list-style: auto;
}
